export const getHeaderLinks = (categories, appData, categoryPath, totalPublicationsCount = 0, withFeedLink = true) =>
  categories.reduce(
    (acc, category) => {
      acc.push({
        key: category._id,
        path: `/${categoryPath}/${category.slug}`,
        text: category.menuLabel,
        postAmount: category.postAmount,
      });
      return acc;
    },
    withFeedLink
      ? [
          {
            key: 'latest_posts',
            path: '/',
            text: appData.menuLabel,
            postAmount: totalPublicationsCount,
          },
        ]
      : [],
  );
